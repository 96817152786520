
              @import "@/assets/css/variables.scss";
            






















































































.cardPhoto {
  width: 150px;
  height: 100px;
}
